import React, { useState } from "react";
import { Breadcrumb, Col, Image, Row } from "react-bootstrap";
import { ChevronRight } from "react-bootstrap-icons";
import { Link, Link as ReactRouterLink, useLocation } from "react-router-dom";
import logo from "../assets/images/instnat_solutions_logo.webp"; // Ensure this path is correct
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faLinkedinIn,
	faSquareFacebook,
	faSquareInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const breadcrumbColor = "#dfe5f3";
const currentBreadcrumbFontWeight = "bold";
const currentBreadcrumbColor = "white";
const transitionDuration = "0.5s";

const navigationItems = [
	{ label: "Начало", to: "/" },
	{ label: "Услуги", to: "/services" },
	{ label: "За нас", to: "/about" },
	{ label: "Пишете ни", to: "/contact-us" },
];

const Navigation = () => {
	const location = useLocation();
	const [menuOpen, setMenuOpen] = useState(false);

	const toggleMenu = () => setMenuOpen(!menuOpen);

	return (
		<nav className="navbar navbar-expand-lg navbar-dark">
			<div
				className="hamburger-menu icons-container"
				onClick={toggleMenu}
			>
				<FontAwesomeIcon icon={faBars} />
				<div className={`my-links ${menuOpen ? "show" : "hide"}`}>
					{navigationItems.map((item, index) => (
						<ReactRouterLink
							key={index}
							to={item.to}
							onClick={() => setMenuOpen(false)}
						>
							{item.label}
						</ReactRouterLink>
					))}
				</div>
			</div>
			<Link to={"/"} className="main-logo">
				<Image src={logo} width="60" height="60" rounded />
			</Link>
			<div className="navigation-container">
				<Row className="navigation-row">
					<Col xs="auto">
						<Breadcrumb
							className="navbar-nav mb-0"
							separator={<ChevronRight />}
						>
							{navigationItems.map((item, index) => {
								const isCurrentPage =
									item.to === location.pathname;

								return (
									<Breadcrumb.Item
										key={index}
										active={isCurrentPage}
										style={{
											fontWeight: isCurrentPage
												? currentBreadcrumbFontWeight
												: undefined,
											color: isCurrentPage
												? currentBreadcrumbColor
												: breadcrumbColor,
											cursor: "pointer",
											transition: `color ${transitionDuration}`,
											display: "flex",
											alignItems: "center",
											textDecoration: "none",
										}}
									>
										<ReactRouterLink
											to={item.to}
											style={{
												color: isCurrentPage
													? currentBreadcrumbColor
													: breadcrumbColor,
												textDecoration: "none",
											}}
										>
											{item.label}
										</ReactRouterLink>
									</Breadcrumb.Item>
								);
							})}
						</Breadcrumb>
					</Col>
				</Row>
			</div>
			<div className="icons-container">
				<Link to={"/"} className="visible-on-mobile-logo">
					<Image src={logo} width="60" height="60" alt="logo" rounded />
				</Link>
				<Link
					to={
						"https://www.facebook.com/profile.php?id=61559910524558"
					}
					target="_blank"
					rel="noopener"
				>
					<FontAwesomeIcon icon={faSquareFacebook} />
				</Link>
				<Link
					to={"https://www.instagram.com/instantsolutions.eu/"}
					target="_blank"
					rel="noopener"
				>
					<FontAwesomeIcon icon={faSquareInstagram} />
				</Link>
				<Link
					to={
						"https://www.linkedin.com/company/instant-solutionsltd/"
					}
					target="_blank"
					rel="noopener"
				>
					<FontAwesomeIcon icon={faLinkedinIn} />
				</Link>
			</div>
		</nav>
	);
};

export default Navigation;
